import React, { Fragment, useEffect, useState } from "react";
const ItemElement = ({
  constants,
  item,
  selectedItems,
  asyncStart,
  asyncEnd,
  updateSelectedItems,
  display,
  removeSelectedItem
}) => {
  const [schedule, setSchedule] = useState([]);
  const [isSelected, setSelect] = useState({ value: false });
  const [isAvailable, setAvailable] = useState(true);
 
  const getSchedule = async (id) => {
    try {
      const response = await fetch(
        constants.API_URL + "/currentlocation/" + id
      );
      const jsonData = await response.json();
      setSchedule(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  const checkAvailability = () => {
    if (typeof asyncStart != "undefined" && typeof asyncEnd != "undefined") {
      let startDateSelected = new Date(asyncStart);
      let endDateSelected = new Date(asyncEnd);

      for (let index = 0; index < schedule.length; index++) {
        let startSchedule = new Date(schedule[index].debut);
        let endSchedule = new Date(schedule[index].fin);
        if (
          startDateSelected <= endSchedule &&
          endDateSelected >= startSchedule
        ) {
          setAvailable(false);
          unSelect();
        } else {
          setAvailable(true);
        }
      }
    }
  };
  const unSelect = () => {
    setSelect({ value: false });
    removeSelectedItem(item);
        
  };
  const handleClick = () => {
    if (isAvailable) {
      if (isSelected.value) {
        unSelect();
      } else {
        setSelect({ value: true });
        let newArray = [...selectedItems];
        newArray.push({ item });
        updateSelectedItems(newArray);
      }
    }
  };
  useEffect(() => {
    getSchedule(item.pk_item);
  }, [item.pk_item]);
  useEffect(() => {
    checkAvailability();
  }, [asyncEnd]);
  useEffect(() => {
    checkAvailability();
  }, [asyncStart]);
 for (let index = 0; index < selectedItems.length; index++) {
    if (selectedItems[index].pk_item === item.pk_item) {
      handleClick();
    }
  }
  return (
    <Fragment>
      <div
        className={
          "flex flex-col justify-end bg-white rounded-xl p-4 m-3 h-80 w-40 lg:h-96 lg:w-72 flex-none hover:shadow-lg transition-all ease-in " +
          (isSelected.value
            ? "border-2 border-moleson"
            : "border-2 border-white ") +
          (isAvailable ? " cursor-pointer " : " cursor-not-allowed ") +
          (display ? "" : "hidden")
        }
        onClick={() => handleClick()}
      >
        <div
          className={
            "has-tooltip flex-grow-0 font-bold text-xs " +
            (isAvailable ? " text-green-400" : "text-red-500")
          }
        >
          {isAvailable ? "Disponible " : "Indisponible "}<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
        <div className="tooltip rounded shadow-lg p-2 bg-gray-100 font-medium text-black text-xs mt-4">
          <p>{schedule.length>0 ? "Prochaines locations :" : "Aucune location de prévu."}</p>
          {schedule.map((location) => (
          <div className="text-red-500 text-xs" key={location.pk_location}>
              <span>{new Date(location.debut).toLocaleDateString("fr-CH")} - </span> 
              <span>{new Date(location.fin).toLocaleDateString("fr-CH")}</span>
            </div>
        ))}</div>
        </div>
        
        <div
          className={
            "flex flex-col flex-grow " + (isAvailable ? " " : "opacity-40 z-0")
          }
        >
          <div className="self-center items-center md:flex-grow">
          <img
              src={process.env.PUBLIC_URL + "/app/public/uploads/" + item.image}
              className="max-h-40 my-4 md:my-6"
              alt={"photo de " + item.nom}
        ></img>
           
          </div>
          <div className={"flex-grow-0 font-black text-xs lg:text-md "}>
            {item.nom}
          </div>
          <div className="flex-grow-0 font-inter font-light text-xs lg:text-sm md:h-20 antialiased overflow-auto ">
            {item.description}
          </div>
          
        </div>
      </div>
    </Fragment>
  );
};
export default ItemElement;
