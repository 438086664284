import React, { useState, useEffect } from "react";
import { HashRouter  as Router, Switch, Route } from "react-router-dom";
import axios from "axios";

import "./index.css";
import Admin from "./Admin";
import Login from "./Login";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import Home from "./Home";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";
const constants = {
  API_URL: "https://location.grangeneuve.ch/app",
  //API_URL: "http://localhost:5000/app"
};

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    axios
      .post(constants.API_URL+`/users/token`, {token : token})
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
        setAuthLoading(false);
      })
      .catch((error) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }
  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/" component={() => <Home constants={constants} />} />
        <PrivateRoute path="/admin" component={() => <Admin constants={constants} />} />
        <PublicRoute path="/login" component={() => <Login constants={constants} />} />
      </Switch>
    </Router>
  );
}

export default App;
