import React, { Fragment, useState } from "react";
const ItemElementEdit = ({constants, item, deleteItem }) => {
  const [titre, setTitre] = useState(item.nom);
  const [description, setDescription] = useState(item.description);
  const [stateSuccess, setStateSuccess] = useState(0);
  const [checked, setChecked] = useState(item.active);

  const handleChangeCheck = () => {
    setChecked(!checked);
  };
  const handleEdit = async (e) => {
    
    try {
      const body = {
        nom: titre,
        description: description,
        active: checked,
      };
      const response = fetch(
       constants.API_URL+ "/item/" + item.pk_item,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      successMsg();
    } catch (err) {
      console.error(err.message);
    } 
  };
  const handleDelete = (e) => {
    deleteItem(item.pk_item);
  };
  const successMsg = () => {
    setStateSuccess(1);
    setTimeout(function() { console.log("state 0");setStateSuccess(0); }, 5000);
  }
  return (
    <Fragment>
      <div className="flex flex-col bg-white rounded-xl p-4 mx-8 my-2 border border-gray-300 hover:shadow-lg transition-all duration-200">
        <div className="flex flex-row">
          <div className="flex self-center justify-center w-44 flex-grow-0">
            <img
              className="w-28"
              src={"app/public/uploads/" + item.image}
              alt="le produit"
            ></img>
          </div>
          <div className="flex flex-col flex-grow">
            <div>Titre</div>
            <div>
              <input
                className="w-full border border-gray-300 p-3 rounded-lg"
                defaultValue={item.nom}
                onChange={(e) => setTitre(e.target.value)}
              ></input>
            </div>
            <div>Description</div>
            <div>
              <textarea
                className="w-full h-20 border border-gray-300 p-3 rounded-lg"
                defaultValue={item.description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChangeCheck}
        />
         {checked ? " Activé" : " Désactivé"}
      </label>
          </div>
        </div>
        
        <div className="flex flex-row text-center content-end justify-end">
          <div className={"bg-green-200 text-green-600 rounded-md border border-green-600 m-2 px-3 flex flex-row items-center justify-center transition-opacity delay-200 ease-in "+(stateSuccess === 0 ? "opacity-0" : "opacity-100")}><div>Objet modifié avec succès !</div></div>
          <div
            className="bg-black text-white rounded-md p-1 px-3 m-2 cursor-pointer border-2 border-black hover:bg-white  hover:text-red-500 flex flex-row justify-center transition-colors duration-200 ease-in-out"
            onClick={() => handleDelete()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            <div className="ml-3">Supprimer l'objet</div>
          </div>
          <div
            className="bg-black text-white rounded-md p-1 m-2 w-44 cursor-pointer border-2 border-black hover:bg-white  hover:text-black flex flex-row justify-center transition-colors duration-200 ease-in-out"
            onClick={() => handleEdit()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <div className="ml-3">Modifier</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ItemElementEdit;
